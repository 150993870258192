import clsx from 'clsx'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Heart, HeartFill, Pencil, Trash } from 'react-bootstrap-icons'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useAddFlashcardTag,
  useDeleteFlashcard,
  useDeleteFlashcardTag,
  useFavoriteFlashcard,
  useFlashcardCreate,
  useFlashcardUpdate,
} from '../../api/flashcards/flashcards'
import { Deck as DeckAPI, Flashcard as FlashcardAPI } from '../../api/model'
import { Typography } from '../../atoms'
import Flashcard, {
  Levels,
  flashcardProperties,
} from '../../atoms/Flashcard/Flashcard'
import TagsInput from '../../atoms/TagsInput/TagsInput'
import convertObjectArrayIntoStringArray from '../../utils/convertObjectArrayIntoStringArray'
import extractContentFromHTML from '../../utils/extractContentFromHTML'
import getFlashcardDifficulty from '../../utils/getFlashcardDifficulty'
import removeEmptyPTags from '../../utils/removeEmptyParagraphTags'
import './FlashcardBoard.css'
import classes from './FlashcardBoard.module.css'

type FormValues = {
  frontSideText: string
  backSideText: string
}

export interface flashcardDeckProperties {
  flashcards: flashcardProperties[]
  name: string
}

interface FlashboardProps {
  show: boolean
  flashcardsDecks: DeckAPI[]
  initialFrontsideText?: string
  initialBacksideText?: string
  currentFlashcardsDeckIdx?: number
  currentFlashcardsDeck?: DeckAPI
  initialFlashcardIdx?: number
  editMode: boolean
  singleSideView: boolean
  handleClose: () => void
  setFlashcardsDecks?: (value: DeckAPI[]) => void
  setEditMode?: (value: boolean) => void
  setSingleSideView?: (value: boolean) => void
  onTagAction?: () => void
}

const FlashcardBoard: FC<FlashboardProps> = ({
  show,
  flashcardsDecks,
  initialFrontsideText = '',
  initialBacksideText = '',
  currentFlashcardsDeckIdx,
  currentFlashcardsDeck,
  initialFlashcardIdx,
  editMode,
  handleClose,
  setFlashcardsDecks = () => {},
  setEditMode = () => {},
  setSingleSideView = () => {},
  onTagAction = () => {},
}) => {
  const { t } = useTranslation()

  const formMethods = useForm<FormValues>()

  // If true, it is showing the front side of the card
  const [frontSideSelected, setFrontSideSelected] = useState(true)

  // If true, the answer (or the backside of the card) is visible
  const [showAnswer, setShowAnswer] = useState(false)

  // The index of the flashcard that is being displayed in the flashcard deck
  const currentFlashcardIndex = useRef<number | undefined>(
    initialFlashcardIdx ?? undefined,
  )

  const [indexChanged, setIndexChanged] = useState<boolean>(false)

  // If true, the modal to delete the flashcard is visible
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [frontSideText, setFrontSideText] = useState<string | undefined>(
    initialFrontsideText,
  )

  const [editedFrontSideText, setEditedFrontSideText] = useState<
    string | undefined
  >(undefined)

  const [backSideText, setBackSideText] = useState<string | undefined>(
    initialBacksideText,
  )

  const [editedBackSideText, setEditedBackSideText] = useState<
    string | undefined
  >(undefined)

  const [showMissingFrontSideText, setShowMissingFrontSideText] =
    useState(false)
  const [showMissingBackSideText, setShowMissingBackSideText] = useState(false)

  // Used to temporarilly store the new deck information
  const [editDeckIdxSelected, setEditDeckIdxSelected] = useState<
    number | undefined
  >(currentFlashcardsDeckIdx ?? 0)

  const [isLoadingFlashcard, setIsLoadingFlashcard] = useState(false)

  /* const { refetch: refetchFlashcards } = useDecksList({
    query: {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  }) */

  const { mutate: createFlashcard } = useFlashcardCreate({
    mutation: {
      onSuccess: (data) => {
        updateFlashcardFromDeck(data)
        //refetchFlashcards()
        handleClose()
      },
    },
  })

  const { mutate: updateFlashcard } = useFlashcardUpdate({
    mutation: {
      onSuccess: (data) => {
        updateFlashcardFromDeck(data)
        //refetchFlashcards()
        handleClose()
      },
    },
  })

  const { mutate: deleteFlashcard } = useDeleteFlashcard({
    mutation: {
      onSuccess: () => {
        deleteFlashcardFromDeck(currentFlashcardIndex.current)
      },
    },
  })

  const { mutate: updateFlashcardFavorite } = useFavoriteFlashcard({
    mutation: {
      onSuccess: (data) => {
        updateFlashcardFromDeck(data)
      },
    },
  })

  const { mutate: addFlashcardTag } = useAddFlashcardTag({
    mutation: {
      onSuccess: (data) => {
        currentFlashcard.tags!.push(data)
        onTagAction()
        //refetchFlashcards()
      },
    },
  })

  const { mutate: deleteFlashcardTag } = useDeleteFlashcardTag({
    mutation: {
      onSuccess: () => {
        onTagAction()
        //refetchFlashcards()
      },
    },
  })

  const toggleFavorite = () => {
    updateFlashcardFavorite({
      flashcardId: currentFlashcard.id!,
      data: { favorite: !currentFlashcard.favorite },
    })
  }

  const flashcards = useMemo(() => {
    return currentFlashcardsDeck?.flashcards ?? []
  }, [currentFlashcardsDeck?.flashcards])

  const selectedFlashcard = useMemo(() => {
    if (currentFlashcardIndex.current !== undefined) {
      return flashcards[currentFlashcardIndex.current]
    } else {
      const newFlashcard: FlashcardAPI = {
        frontside_text: '',
        backside_text: '',
        favorite: false,
        tags: [],
      }

      return newFlashcard
    }
  }, [flashcards, indexChanged])

  const currentFlashcard = useMemo(() => {
    const currentFlashcard = structuredClone(selectedFlashcard)

    currentFlashcard.frontside_text = currentFlashcard.frontside_text?.concat(
      initialFrontsideText ?? '',
    )
    currentFlashcard.backside_text = currentFlashcard.backside_text?.concat(
      initialBacksideText ?? '',
    )

    // trim the text from the flashcard
    currentFlashcard.frontside_text = removeEmptyPTags(
      currentFlashcard.frontside_text!,
    )
    currentFlashcard.backside_text = removeEmptyPTags(
      currentFlashcard.backside_text!,
    )

    setFrontSideText(currentFlashcard.frontside_text)
    setBackSideText(currentFlashcard.backside_text)

    return currentFlashcard
  }, [selectedFlashcard])

  const goToNextCard = () => {
    if (
      currentFlashcardIndex.current !== undefined &&
      currentFlashcardIndex.current < flashcards.length - 1
    ) {
      currentFlashcardIndex.current = currentFlashcardIndex.current + 1
      setIndexChanged(!indexChanged)

      resetView()
    }
  }

  const goToPreviousCard = () => {
    if (
      currentFlashcardIndex.current !== undefined &&
      currentFlashcardIndex.current > 0
    ) {
      currentFlashcardIndex.current = currentFlashcardIndex.current - 1

      setIndexChanged(!indexChanged)

      resetView()
    }
  }

  const resetView = () => {
    setFrontSideSelected(true)
    setShowAnswer(false)

    setIsLoadingFlashcard(true)

    setTimeout(() => {
      setIsLoadingFlashcard(false)
    }, 600)
  }

  const toggleShowAnswer = () => {
    setShowAnswer(!showAnswer)
  }

  const onShowAnswerButtonClicked = () => {
    toggleShowAnswer()
  }

  const onEditButtonClicked = () => {
    setEditMode(true)
    setShowAnswer(true)
  }

  const checkIfFlashcardSideIsEmpty = () => {
    if (
      !extractContentFromHTML(editedFrontSideText) &&
      !editedFrontSideText?.includes('<img')
    ) {
      alert(t('flashcards.errors.frontsideEmpty'))
      throw new Error('Frontside is empty')
    } else {
      if (
        !extractContentFromHTML(editedBackSideText) &&
        !editedBackSideText?.includes('<img')
      ) {
        alert(t('flashcards.errors.backsideEmpty'))
        throw new Error('Backside is empty')
      }
    }
  }

  const updateFlashcardFromDeck = ({
    id,
    frontside_text: newFrontsideText,
    backside_text: newBacksideText,
    level,
    favorite,
    tags,
  }: FlashcardAPI) => {
    // If we're editing an existing flashcard
    if (currentFlashcardIndex.current !== undefined) {
      // update flashcard
      currentFlashcard!.frontside_text = newFrontsideText
      currentFlashcard!.backside_text = newBacksideText
      currentFlashcard!.favorite = favorite ?? false
      currentFlashcard!.level = level ?? Levels.MODERATE
      currentFlashcard!.tags = tags

      flashcards[currentFlashcardIndex.current] = currentFlashcard!

      if (
        editDeckIdxSelected !== undefined &&
        currentFlashcardsDeckIdx !== undefined &&
        currentFlashcardsDeckIdx !== editDeckIdxSelected
      ) {
        flashcardsDecks[editDeckIdxSelected].flashcards.push(currentFlashcard)

        const newFlashcardsDeck = flashcardsDecks[
          currentFlashcardsDeckIdx
        ].flashcards
          .filter((item, index) => index !== currentFlashcardIndex.current)
          .slice()

        // To refresh the flashcards array
        const newFlashcardsDecks = [...flashcardsDecks]

        newFlashcardsDecks[currentFlashcardsDeckIdx].flashcards =
          newFlashcardsDeck

        setFlashcardsDecks(newFlashcardsDecks)
      }
    }
    // If we're creating a new flashcard
    else {
      const newFlashcard: FlashcardAPI = {
        id: id,
        frontside_text: newFrontsideText,
        backside_text: newBacksideText,
        level: Levels.MODERATE,
        favorite: false,
        tags: tags,
      }

      let deckIdx = 0

      // If the user changed the flashcard to another deck
      if (editDeckIdxSelected !== undefined) {
        deckIdx = editDeckIdxSelected
      }

      // To refresh the flashcards array
      const newFlashcardsDecks = [...flashcardsDecks]

      newFlashcardsDecks[deckIdx!].flashcards.push(newFlashcard)

      setFlashcardsDecks(newFlashcardsDecks)
    }
  }

  const onCancelEditClicked = () => {
    handleClose()
    setEditMode(false)
    setFrontSideText(currentFlashcard?.frontside_text)
    setBackSideText(currentFlashcard?.backside_text)
    resetView()
    // when the flashcard is empty, it closes the modal
  }

  const deleteFlashcardFromDeck = (indexToDelete?: number) => {
    if (currentFlashcardsDeck) {
      currentFlashcardsDeck.flashcards = flashcards.filter(
        (item, index) => index !== indexToDelete,
      )

      // If the flashcards array is empty, closes the modal
      if (currentFlashcardsDeck?.flashcards.length === 0) {
        handleClose()
        return
      }

      currentFlashcardIndex.current = 0

      setIndexChanged(!indexChanged)

      setShowDeleteModal(false)
    }
  }

  const onSubmitFlashcardEdit: SubmitHandler<FormValues> = async () => {
    if (editedFrontSideText === '') {
      setShowMissingFrontSideText(true)
      return
    }

    if (editedBackSideText === '') {
      setShowMissingBackSideText(true)
      return
    }

    setShowMissingFrontSideText(false)
    setShowMissingBackSideText(false)

    // If the user didn't write anything on the flashcard fires an error notification and doesn't save
    checkIfFlashcardSideIsEmpty()

    // Trim the text from the flashcard
    const trimmedFrontSideText = removeEmptyPTags(editedFrontSideText!)
    const trimmedBackSideText = removeEmptyPTags(editedBackSideText!)

    // If we're editing an existing flashcard
    if (currentFlashcardIndex.current !== undefined) {
      const updatedFlashcard = {
        frontside_text: trimmedFrontSideText,
        backside_text: trimmedBackSideText,
        deck_id: flashcardsDecks[editDeckIdxSelected!]?.id,
        tags: [],
      }

      // If the user changed the flashcard to another deck
      if (editDeckIdxSelected !== undefined) {
        updatedFlashcard.deck_id = flashcardsDecks[editDeckIdxSelected!]?.id
      } else {
        updatedFlashcard.deck_id =
          flashcardsDecks[currentFlashcardsDeckIdx!]?.id
      }

      updateFlashcard({
        flashcardId: currentFlashcard.id!,
        data: updatedFlashcard,
      })
    }

    // If we're creating a new flashcard
    else {
      const newFlashcard = {
        frontside_text: editedFrontSideText,
        backside_text: editedBackSideText,
        deck_id: '',
        tags: [],
      }

      // If the user changed the flashcard to another deck
      if (editDeckIdxSelected !== undefined) {
        newFlashcard.deck_id = flashcardsDecks[editDeckIdxSelected!]?.id!
      } else {
        newFlashcard.deck_id = flashcardsDecks[0]?.id!
      }

      createFlashcard({ data: newFlashcard })
    }
    setEditMode(false)
    resetView()
  }

  const onAddTag = (tag: string) => {
    addFlashcardTag({
      data: { title: tag, flashcard: currentFlashcard.id! },
    })
  }

  const onDeleteTag = (index: number) => {
    deleteFlashcardTag({
      tagId: currentFlashcard.tags![index].id!,
    })

    currentFlashcard.tags!.splice(index, 1)
  }

  const handleKeyPressed = (e: KeyboardEvent) => {
    if (!show) return

    switch (e.key) {
      case ' ':
        if (!editMode) {
          onShowAnswerButtonClicked()
        }
        break
      case 'ArrowLeft':
        if (!editMode) {
          goToPreviousCard()
        }
        break
      case 'ArrowRight':
        if (!editMode) {
          goToNextCard()
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    currentFlashcardIndex.current = initialFlashcardIdx

    setIndexChanged(!indexChanged)
  }, [initialFlashcardIdx])

  useEffect(() => {
    if (editMode) setSingleSideView(false)
  }, [editMode])

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPressed, true)
    return () => {
      document.removeEventListener('keyup', handleKeyPressed, true)
    }
  }, [
    show,
    editMode,
    indexChanged,
    flashcards.length,
    showAnswer,
    frontSideSelected,
  ])

  useEffect(() => {
    setEditedFrontSideText(frontSideText)
    setEditedBackSideText(backSideText)
  }, [frontSideText, backSideText])

  return (
    <>
      <Modal
        show={show}
        /* dialogAs={DraggableModalDialog} */
        dialogClassName={classes.flascardModal}
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('flashcards.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={clsx(classes.modalBody, classes.flashModal)}>
          <Row className="justify-content-between align-items-center">
            <Col sm={6} md={4} lg={4} className="mb-2">
              {!editMode ? (
                <>
                  <div className="d-flex align-items-center">
                    <div
                      className={clsx(classes.circle, 'mr-1')}
                      style={{
                        backgroundColor: currentFlashcardsDeck?.color,
                        width: 20,
                        height: 20,
                        marginRight: 10,
                        padding: 5,
                        border: '0.5px solid rgb(33, 37, 41)',
                      }}
                    >
                      <Typography className="mb-0" variant="h6">
                        {currentFlashcardsDeck?.name
                          .toUpperCase()
                          .substring(0, 2)}
                      </Typography>
                    </div>
                    <Typography className="mb-0" variant="h5">
                      {currentFlashcardsDeck?.name}
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <Form.Label>
                    <Typography variant="h5" className="mb-0">
                      {t('flashcards.deck')}
                    </Typography>
                  </Form.Label>
                  <Form.Select
                    defaultValue={
                      currentFlashcardsDeckIdx === undefined
                        ? initialFlashcardIdx
                        : currentFlashcardsDeckIdx
                    }
                    onChange={(e) => {
                      setEditDeckIdxSelected(e.target.value)
                    }}
                  >
                    {flashcardsDecks.map((flashcardDeck, idx) => (
                      <option key={flashcardDeck.name} value={idx}>
                        {flashcardDeck.name}
                      </option>
                    ))}
                  </Form.Select>
                </>
              )}
            </Col>
            <Col>
              <Typography
                variant="h6"
                fontWeight="bold"
                className="mb-0 text-center"
              >
                {getFlashcardDifficulty(t, currentFlashcard)}
              </Typography>
            </Col>
            <Col sm={4} md={4} lg={4}>
              {!editMode ? (
                <div className="d-flex justify-content-end align-items-center">
                  <div
                    onClick={() => {
                      toggleFavorite()
                    }}
                  >
                    {currentFlashcard?.favorite ? (
                      <HeartFill
                        className={clsx(classes.heart, classes.active)}
                      />
                    ) : (
                      <Heart className={classes.heart} />
                    )}
                  </div>
                  <div
                    onClick={onEditButtonClicked}
                    className="d-flex align-items-center"
                  >
                    <Pencil className={classes.icon} />
                  </div>
                  <div
                    onClick={() => setShowDeleteModal(true)}
                    className="d-flex align-items-center"
                  >
                    <Trash className={classes.icon} />
                  </div>
                </div>
              ) : null}
            </Col>
          </Row>

          {currentFlashcard ? (
            <FormProvider {...formMethods}>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Flashcard
                  frontSideText={frontSideText!}
                  backSideText={backSideText!}
                  frontSideSelected={frontSideSelected}
                  showAnswer={showAnswer}
                  editMode={editMode}
                  isLoading={isLoadingFlashcard}
                  showMissingFrontSideText={showMissingFrontSideText}
                  showMissingBackSideText={showMissingBackSideText}
                  setFrontSideSelected={setFrontSideSelected}
                  setShowAnswer={setShowAnswer}
                  setFrontSideText={setEditedFrontSideText}
                  setBackSideText={setEditedBackSideText}
                />
                {currentFlashcard.id && (
                  <div className="mb-4">
                    <TagsInput
                      parent={currentFlashcard.id!}
                      tags={convertObjectArrayIntoStringArray(
                        currentFlashcard.tags,
                        'title',
                      )}
                      placeholder={t('notepad.insertTagsPlaceholder')}
                      onAddTag={onAddTag}
                      onDeleteTag={onDeleteTag}
                    />
                  </div>
                )}
                <div className="d-flex justify-content-center position-relative z-1">
                  {!editMode ? (
                    <>
                      <Button
                        onClick={goToPreviousCard}
                        disabled={currentFlashcardIndex.current === 0}
                        variant="dark"
                        className={classes.flashcardCarouselButton}
                      >
                        {t('common.previous')}
                      </Button>
                      <Button
                        onClick={goToNextCard}
                        disabled={
                          currentFlashcardIndex.current ===
                          flashcards.length - 1
                        }
                        variant="dark"
                        className={classes.flashcardCarouselButton}
                      >
                        {t('common.next')}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={onCancelEditClicked}
                        variant="dark"
                        className="me-4"
                      >
                        {t('common.cancel')}
                      </Button>

                      <Button
                        variant="success"
                        type="button"
                        onClick={(args) =>
                          formMethods.handleSubmit(onSubmitFlashcardEdit(args))
                        }
                      >
                        {t('common.save')}
                      </Button>
                    </>
                  )}
                </div>
              </Form>
            </FormProvider>
          ) : null}
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{t('flashcards.deleteFlashcard')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('flashcards.deleteFlashcardDescription')}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            onClick={() => {
              setShowDeleteModal(false)
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={() =>
              deleteFlashcard({ flashcardId: currentFlashcard.id! })
            }
          >
            {t('common.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FlashcardBoard
